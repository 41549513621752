import type { PayeesFieldItem } from 'app/features/payments/paymentsV2/buildPaymentsForms/types';

export interface InitialSerachValueProps {
  payee?: PayeesFieldItem;
  // account: PickSubscriptionEntityExcludeKeyofSubscriptionEntityOrMemberIdentificationOrTenantKeyOrBalancesOrPartyRolesOrIsFirstCreditDone &
  //   ProcessedSubscriptionEntityAllOf &
  //   ProcessedSubscriptionEntityAllOfTwo &
  //   PartialSummaryArrangementType &
  //   AllSubscriptionsResponseAllOf;
  // isLoanPayment: boolean;
}
// TODO: this breaks the Payee Typeahead, but it's needed for new payees or when the user goes back from the next step
export const getInitialSearchValue = ({ payee }: InitialSerachValueProps) => {
  //Unique case for lending repayments
  // if (isLoanPayment) {
  //   return `${account.nickname} BSB ${account.bsb} ${account.accountNumber}`;
  // }
  if (!payee) {
    return undefined;
  }
  switch (payee.paymentType) {
    case 'AUS_INTERNAL_TRANSFER':
    case 'AUS_BANK_ACCOUNT': {
      if (payee.paymentDetails.bsb && payee.paymentDetails.accountNumber) {
        return `${payee.nickname || payee.name} BSB ${payee.paymentDetails.bsb} ${payee.paymentDetails.accountNumber}`;
      }
      return undefined;
    }
    case 'AUS_BPAY':
      if (payee.paymentDetails.billerCode) {
        return `${payee.nickname || payee.name} Biller code ${payee.paymentDetails.billerCode} Ref ${
          payee.paymentDetails.referenceNumber
        }`;
      }
      return undefined;

    case 'AUS_PAYID':
      if (payee.paymentDetails.value) {
        return `${payee.nickname || payee.name} ${payee.paymentDetails.value}`;
      }
      return undefined;
    default:
      return undefined;
  }
};
