import { H4, Text } from '@cxnpl/ui';
import { Platform } from 'react-native';
import { itemToLabel, itemToValue } from 'app/components';
import { frequencyItemToLabel, frequencyItemToValue } from 'app/shared/frequencyField';
import { getInitialSearchValue } from 'app/features/payments/utils/getInitialSearchValue';
import i18n from 'app/i18n/i18n.config';
import { paymentFrequencies, sendDelayedOptions } from './types';
import type { PaymentsFormValues } from './types';
import { fromAccountItemToLabelNative } from './selectCustomRenderers/FromAccountOptionRender';
import { ToAccountItemToLabelNative } from './selectCustomRenderers/ToAccountOptionRender';

export const updateFormProps = ({
  newPaymentValues,
  isLendingToAccount,
}: {
  newPaymentValues: Partial<PaymentsFormValues>;
  isLendingToAccount: boolean;
}) => {
  const paymentFormPropsRedefined = {
    accountId: {
      testID: 'from-account-select',
      fieldLabel: Platform.OS === 'web' ? i18n.t('payments.makePayment.from') : undefined,
      itemToLabel: Platform.OS === 'web' ? itemToLabel : fromAccountItemToLabelNative,
      removePadding: Platform.OS === 'ios' || Platform.OS === 'android',
      itemToValue,
      variant: Platform.OS !== 'web' ? 'no-outline' : 'outlined',
    },
    payee: {
      testID: 'to-account-select',
      disabled: !!isLendingToAccount,
      noItemsFoundMessage: i18n.t('payments.payee.noMatchMessage'),
      initialSearchValue: getInitialSearchValue({ payee: newPaymentValues.payee }),
      variant: Platform.OS !== 'web' ? 'no-outline' : undefined,
      fieldLabel: Platform.OS === 'web' ? i18n.t('payments.makePayment.to') : undefined,
      itemToLabel: Platform.OS === 'web' ? itemToLabel : ToAccountItemToLabelNative,
      removePadding: Platform.OS === 'ios' || Platform.OS === 'android',
      itemToValue,
    },
    amount: {
      fieldLabel: 'Amount',
      testID: 'amount',
      variant: Platform.OS === 'ios' || Platform.OS === 'android' ? 'singleInput' : 'default',
      startIcon: Platform.OS === 'web' ? <Text marginLeft="$md">$</Text> : <H4>$</H4>, // Single input on OS vs normal input on web
    },
    description: {
      fieldLabel: 'Description',
      variant: Platform.OS === 'ios' || Platform.OS === 'android' ? 'singleInput' : 'default',
      hint: (() => {
        if (newPaymentValues.payee?.paymentType === 'AUS_INTERNAL_TRANSFER') {
          return i18n.t('payments.makePayment.paymentForm.baseForm.description.transferProp');
        } else if (
          newPaymentValues.payee?.supportedSchemes[0]?.scheme === 'AU_DE' ||
          (newPaymentValues.payee?.paymentType === 'AUS_BANK_ACCOUNT' && newPaymentValues.sendDelayed === 'Later')
        ) {
          return i18n.t('payments.makePayment.paymentForm.baseForm.description.deProp');
        } else if (newPaymentValues.payee?.supportedSchemes[0]?.scheme === 'AU_NPP_SCT') {
          return i18n.t('payments.makePayment.paymentForm.baseForm.description.deSccProp');
        }
        return i18n.t('payments.makePayment.paymentForm.baseForm.description.defaultProps');
      })(),
    },
    reference: {
      fieldLabel: 'Reference',
      hint: i18n.t('payments.makePayment.charLimit'),
      variant: Platform.OS === 'ios' || Platform.OS === 'android' ? 'singleInput' : 'default',
    },
    sendDelayed: {
      fieldLabel: 'Send',
      items: sendDelayedOptions,
      itemToLabel: frequencyItemToLabel,
      itemToValue: frequencyItemToValue,
    },
    startDate: {},
    repeat: {
      fieldLabel: 'Repeat',
      items: paymentFrequencies,
      itemToLabel: frequencyItemToLabel,
      itemToValue: frequencyItemToValue,
    },
  };
  return paymentFormPropsRedefined;
};

export type PaymentFormProps = ReturnType<typeof updateFormProps>;
