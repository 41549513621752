//  * External payee as the object needed to post a transaction (unlike the 'incomplete' one that comes in the get API)

import type { z } from 'zod';
import { frequencyValues } from 'app/shared/frequencyField';
import type { BankAccountPayee } from './paymentRequestSchemas/uniquePaymentSchemas/AusBankAccountSchema';
import type { InternalPayee } from './paymentRequestSchemas/uniquePaymentSchemas/AusInternalTransferSchema';
import type { BPayPayee } from './paymentRequestSchemas/uniquePaymentSchemas/bpaySchema';
import type { PayIdAbnPayee } from './paymentRequestSchemas/uniquePaymentSchemas/payIdAbnSchema';
import type { PayIdEmailPayee } from './paymentRequestSchemas/uniquePaymentSchemas/payIdEmailSchema';
import type { PayIdOrgIdPayee } from './paymentRequestSchemas/uniquePaymentSchemas/payIdOrgSchema';
import type { PayIdPhonePayee } from './paymentRequestSchemas/uniquePaymentSchemas/payIdPhoneSchema';
import type {
  BasePaymentsFormSchema,
  ExternalPaymentFormSchemaBase,
  InternalTransferFormSchemaBase,
  PaymentDescriptionSchema,
} from './paymentRequestSchemas/baseSchema';

export type PayIdPayee = PayIdPhonePayee | PayIdEmailPayee | PayIdAbnPayee | PayIdOrgIdPayee;

export type ExternalPayee = BankAccountPayee | BPayPayee | PayIdPayee;
//  ----------------------------- Payee Field Types -----------------------------
export type PayeesFieldItem = InternalPayee | ExternalPayee;
export type PayeesFieldItemPaymentType = InternalPayee['paymentType'] | ExternalPayee['paymentType'];

//  ----------------------------- Add Payee Form Types -----------------------------
export type ExternalPayeePaymentType =
  | Exclude<ExternalPayee['paymentType'], 'AUS_PAYID'>
  | `AUS_PAYID_${PayIdPayee['paymentDetails']['identifier']}`;

export type BasePaymentsFormSchemaType = z.infer<typeof BasePaymentsFormSchema>;
export type PaymentsFormFields = z.infer<typeof BasePaymentsFormSchema>;

export type PaymentsFormValues = Omit<PaymentsFormFields, 'accountId' | 'payee'> & {
  accountId: string;
  payee: PayeesFieldItem;
};

export interface PaymentFrequencyFieldItem {
  label: string;
  value: string;
}

export const paymentFrequencies: PaymentFrequencyFieldItem[] = frequencyValues as PaymentFrequencyFieldItem[];

export const sendDelayedOptions: PaymentFrequencyFieldItem[] = [
  { label: 'Now', value: 'Now' },
  { label: 'Later', value: 'Later' },
];

export type BasePaymentsInternalTransferFormFields = z.infer<typeof InternalTransferFormSchemaBase>;

export type BasePaymentsExternalFormFields = z.infer<typeof ExternalPaymentFormSchemaBase>;

export type PaymentsDescriptionFormFields = z.infer<typeof PaymentDescriptionSchema>;
