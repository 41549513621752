import type { BRAND } from 'zod';
import type { PayeesFieldItem } from 'app/features/payments/paymentsV2/buildPaymentsForms/types';

/* Render reference field under the following conditions:
      1. Payment type is not 'AUS_INTERNAL_TRANSFER' or 'AUS_BPAY'
      2. Supported scheme is 'AU_NPP_OSKO' or payment type is 'AUS_PAYID'
      3. Payment is not scheduled or recurring
        */
export const getShowReferenceField = (
  selectedToAccount: PayeesFieldItem | string | undefined,
  sendDelayedValue: (string & BRAND<'select'>) | undefined,
  repeatValue: (string & BRAND<'select'>) | undefined
) => {
  if (typeof selectedToAccount === 'string') {
    return false;
  }
  const result =
    !(selectedToAccount?.paymentType === 'AUS_INTERNAL_TRANSFER' || selectedToAccount?.paymentType === 'AUS_BPAY') &&
    (selectedToAccount?.supportedSchemes[0]?.scheme === 'AU_NPP_OSKO' ||
      selectedToAccount?.paymentType === 'AUS_PAYID') &&
    !(selectedToAccount.paymentType === 'AUS_BANK_ACCOUNT' && sendDelayedValue === 'Later') &&
    !(
      sendDelayedValue === 'Later' ||
      ['Daily', 'Weekly', 'Fortnightly', 'Monthly', 'Quarterly', 'Yearly'].includes(repeatValue || '')
    );
  return result;
};
