import { getShowReferenceField } from 'app/features/payments/utils/getShowReferenceField';
import type { PaymentsFormValues } from './types';

export interface ShowPaymentFields {
  accountId: boolean;
  payee: boolean;
  amount: boolean;
  description: boolean;
  reference: boolean;
  sendDelayed: boolean;
  startDate: boolean;
  repeat: boolean;
  endDate: boolean;
}

export const updateVisibleFields = ({
  newPaymentValues,
  isLendingFromAccount,
  isLendingToAccount,
}: {
  newPaymentValues: Partial<PaymentsFormValues>;
  isLendingFromAccount: boolean;
  isLendingToAccount: boolean;
}) => {
  // Determine Which Fields are Visable
  const showReferenceField = getShowReferenceField(
    newPaymentValues.payee,
    newPaymentValues.sendDelayed,
    newPaymentValues.repeat
  );

  const showSendDelayedField =
    !isLendingFromAccount && !isLendingToAccount && newPaymentValues.payee?.paymentType !== 'AUS_PAYID';
  const showRepeatField = showSendDelayedField;
  const showStartDate =
    showSendDelayedField && newPaymentValues.sendDelayed && newPaymentValues.sendDelayed === 'Later';
  const showEndDate = showSendDelayedField && newPaymentValues.repeat && newPaymentValues.repeat !== 'Never';

  return {
    accountId: true,
    payee: true,
    amount: true,
    description: newPaymentValues.payee?.paymentType !== 'AUS_BPAY',
    reference: showReferenceField,
    sendDelayed: showSendDelayedField,
    startDate: !!showStartDate,
    repeat: showRepeatField,
    endDate: !!showEndDate,
  } as ShowPaymentFields;
};
