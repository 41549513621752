/* eslint-disable react/hook-use-state -- Pending implementation */
/* eslint-disable react-hooks/exhaustive-deps -- callbacks and useEffects do not require all dependencies */
'use client';

import { useCallback, useEffect, useState } from 'react';
import { useInternalTransfer } from '@cxnpl/api/payments';
import { useQueryClient } from '@tanstack/react-query';
import { getGetAccountsQueryKey } from '@cxnpl/api/accounts';
import type { AddPayeeBody, ExternalTransaction, InternalTransaction } from '@cxnpl/api/types';
import { randomUUID } from 'expo-crypto';
import {
  getGetPaymentsQueryKey,
  getPaymentValidationQueryKey,
  paymentValidation,
} from '@cxnpl/api/payments-creation-and-authorization';
import { useRouter } from '@cxnpl/router';
import { getErrorMessage } from '@cxnpl/api/axios';
import { useAddPayee } from '@cxnpl/api/payees';
import { Platform } from 'react-native';
import { toNumberAmount } from 'app/utils/amountFormatter';
import { getPaymentSchedule } from 'app/features/payments/utils/paymentSchedule';
import { useToast } from 'app/hooks';
import type { PaymentRequest } from 'app/api/payments/types';
import { useExternalPaymentRequest, useExternalPaymentProcess } from 'app/api/payments/api';
import type { PayeesFieldItem, PaymentsFormValues } from 'app/features/payments/paymentsV2/buildPaymentsForms/types';
import type { PaymentFormProps } from 'app/features/payments/paymentsV2/buildPaymentsForms/updateFormProps';
import { updateFormProps } from 'app/features/payments/paymentsV2/buildPaymentsForms/updateFormProps';
import type { ShowPaymentFields } from 'app/features/payments/paymentsV2/buildPaymentsForms/updateVisibleFormFields';
import { updateVisibleFields } from 'app/features/payments/paymentsV2/buildPaymentsForms/updateVisibleFormFields';
import { formatPhoneNumber } from 'app/features/payments/utils/mobileNumberFormatter';
import { useStepUpAuth } from '../StepUpAuth';
import type { PaymentTypes, PaymentsBuilderStates } from './types';
import { generateExternalPaymentPayload } from './paymentRequestPayloads/generateExternalPaymentPayload';
import { generateNewPaymentRequestPayload } from './paymentRequestPayloads/generatePaymentRequest';
import { PaymentsBuilderServiceContext } from './PaymentsBuilderServiceContext';

export const PaymentsBuilderServiceProvider = ({ children }: { children: JSX.Element }) => {
  // Payment Engine States
  const [paymentType, setPaymentType] = useState<PaymentTypes>();
  const [paymentRequestId, setPaymentRequestId] = useState<string>();
  const [paymentsBuilderState, setPaymentsBuilderState] = useState<PaymentsBuilderStates>('initializing');
  const [paymentValues, setPaymentValues] = useState<Partial<PaymentsFormValues>>();
  const [transactionResults, setTransactionResults] = useState<
    InternalTransaction | ExternalTransaction | PaymentRequest
  >();

  // Form State Management
  const [paymentFormProps, setPaymentFormProps] = useState<PaymentFormProps>();

  const [fieldsToShow, setFieldsToShow] = useState<ShowPaymentFields>({
    accountId: false,
    payee: false,
    amount: false,
    description: false,
    reference: false,
    sendDelayed: false,
    startDate: false,
    repeat: false,
    endDate: false,
  });

  // TODO Lending Controllers
  const [isLendingToAccount, _setIsLendingToAccount] = useState<boolean>(false);
  const [isLendingFromAccount, _setIsLendingFromAccount] = useState<boolean>(false);
  const [showAnimationStart, setShowAnimationStart] = useState(false);
  const [playPaymentSuccessAnimation, setPlayPaymentSuccessAnimation] = useState(false);

  const queryClient = useQueryClient();
  const router = useRouter();
  const { showToast } = useToast();

  /** Mutations */
  const { mutateAsync: createInternalTransfer } = useInternalTransfer({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: getGetAccountsQueryKey(),
        });
      },
    },
  });
  const { mutateAsync: createExternalPaymentRequest } = useExternalPaymentRequest();
  const { mutateAsync: createPayee } = useAddPayee();
  const { mutateAsync: processExternalPayment } = useExternalPaymentProcess({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: getGetPaymentsQueryKey(),
        });
        await queryClient.invalidateQueries({
          queryKey: getGetAccountsQueryKey(),
        });
      },
    },
  });

  /** MFA */
  const { requireMfa } = useStepUpAuth();

  /** Based on changing state, drive action on the front end */
  useEffect(() => {
    if (paymentsBuilderState === 'reset') {
      const newPaymentRequest = startNewPaymentRequestCallback({
        keepExistingInformation: false,
        type: 'AUS_BANK_ACCOUNT',
      });
      router.replace(`/payments?requestId=${newPaymentRequest.newID}`);
    }
    if (paymentsBuilderState === 'reset_to_form') {
      if (Platform.OS === 'web') {
        router.replace(`/payments?requestId=${paymentRequestId}`);
        setShowAnimationStart(false);
        setPlayPaymentSuccessAnimation(false);
        setPaymentsBuilderState('ready_to_build');
      }
      // Native payments
      else if (paymentType === 'AUS_INTERNAL_TRANSFER') {
        router.replace(`/payments/transfer?requestId=${paymentRequestId}`);
        setShowAnimationStart(false);
        setPlayPaymentSuccessAnimation(false);
        setPaymentsBuilderState('ready_to_build');
      } else {
        router.replace(`/payments/external-payment?requestId=${paymentRequestId}`);
        setShowAnimationStart(false);
        setPlayPaymentSuccessAnimation(false);
        setPaymentsBuilderState('ready_to_build');
      }
    }
    // Internal Transfer flow routes
    if (paymentsBuilderState === 'ready_to_review_internal_request') {
      router.push('/payments/review/internal');
    }
    if (paymentsBuilderState === 'processing_internal_payment_request_BE') {
      setShowAnimationStart(true);
    }
    if (paymentsBuilderState === 'results_internal_payment_requests_ready') {
      setShowAnimationStart(true);
      setPlayPaymentSuccessAnimation(true);
    }

    // External Payment flows
    if (paymentsBuilderState === 'ready_to_review_external_request') {
      router.push('/payments/review/external');
    }
    if (paymentsBuilderState === 'processing_external_payment_request_BE') {
      setShowAnimationStart(true);
    }
    if (paymentsBuilderState === 'results_external_payment_requests_ready') {
      setShowAnimationStart(true);
      setPlayPaymentSuccessAnimation(true);
    }
  }, [paymentsBuilderState]);

  // Start a new payment request
  const startNewPaymentRequestCallback = useCallback(
    ({
      type,
      keepExistingInformation,
      oldPaymentValues,
    }: {
      type: PaymentTypes;
      keepExistingInformation: boolean;
      oldPaymentValues?: Partial<PaymentsFormValues>;
    }) => {
      setShowAnimationStart(false);
      setPlayPaymentSuccessAnimation(false);

      const newID = randomUUID();
      if (!keepExistingInformation) {
        setPaymentRequestId(newID);
      }
      setPaymentType(type);

      const { newPaymentValues } = generateNewPaymentRequestPayload({
        paymentType: type,
        oldPaymentValues,
        keepExistingInformation,
      });
      setPaymentValues(newPaymentValues);

      // Set Props For Schema Form
      const newFormProps = updateFormProps({ newPaymentValues, isLendingToAccount });
      setPaymentFormProps(newFormProps);

      // Update visible form fields
      const newFieldsToShow = updateVisibleFields({
        newPaymentValues,
        isLendingFromAccount,
        isLendingToAccount,
      });
      setFieldsToShow(newFieldsToShow);
      setPaymentsBuilderState('ready_to_build');
      return { newID, newPaymentValues };
    },
    []
  );

  // If the to field changes, change the payload but then start new payment request after.
  const patchPaymentRequestCallback = useCallback(
    ({ updatedValues }: { updatedValues: Partial<PaymentsFormValues> }) => {
      setPaymentValues(updatedValues);
      const newFormProps = updateFormProps({ newPaymentValues: updatedValues, isLendingToAccount });
      setPaymentFormProps(newFormProps);
      const newFieldsToShow = updateVisibleFields({
        newPaymentValues: updatedValues,
        isLendingFromAccount,
        isLendingToAccount,
      });
      setFieldsToShow(newFieldsToShow);
      setPaymentsBuilderState('ready_to_build');
    },
    []
  );

  // Manually change the state of the service
  const updatePaymentsServiceStateCallback = useCallback((state: PaymentsBuilderStates) => {
    setPaymentsBuilderState(state);
  }, []);

  const submitFormCallback = useCallback(async (currentPaymentValues: Partial<PaymentsFormValues> | undefined) => {
    if (!currentPaymentValues) {
      return;
    }
    const payee = currentPaymentValues.payee as unknown as PayeesFieldItem;

    // Internal transfer
    if (payee.paymentType === 'AUS_INTERNAL_TRANSFER') {
      setPaymentsBuilderState('ready_to_review_internal_request');
      return;
    }

    // Generate External payment payload
    const createExternalPaymentRequestBody = generateExternalPaymentPayload(currentPaymentValues);

    // If there is an existing payId on file
    // No MFA it required - proceed to review of the payment
    if (payee.payeeId) {
      setPaymentsBuilderState('create_external_payment_request_BE');
      try {
        const result = await createExternalPaymentRequest({
          data: {
            ...createExternalPaymentRequestBody,
            payeeId: payee.payeeId,
          },
        });
        setPaymentsBuilderState('ready_to_review_external_request');
        setPaymentRequestId(result.paymentId as string);
      } catch (e) {
        const errorMessage = getErrorMessage(e);
        let finalMessage = errorMessage;
        if (errorMessage === 'Invalid reference number') {
          finalMessage = 'The reference number provided is invalid for this Biller.';
        } else if (errorMessage === 'Invalid amount') {
          finalMessage = 'This Biller cannot accept the amount you have specified.';
        }
        showToast({ title: finalMessage, severity: 'danger' });
        setPaymentsBuilderState('ready_to_build');
      }
    } else if (payee.savePayee) {
      setPaymentsBuilderState('create_external_payment_request_BE');
      await requireMfa({
        scope: 'payee:add',
        onSubmit: async ({ mfaId, mfaCode }) => {
          let paymentDetails = payee.paymentDetails;
          if ('identifier' in payee.paymentDetails && payee.paymentDetails.identifier === 'TELEPHONE') {
            const { countryCode, ...rest } = payee.paymentDetails;
            const value: string = formatPhoneNumber(countryCode, rest.value);

            paymentDetails = {
              ...rest,
              value,
            };
          }
          // Create payee
          const createdPayee = await createPayee({
            data: {
              name: payee.name,
              nickname: payee.nickname,
              paymentType: payee.paymentType,
              paymentDetails,
              mfaId,
              mfaCode,
            } as AddPayeeBody,
          });

          // Create payment request
          const { paymentId } = await createExternalPaymentRequest({
            data: { ...createExternalPaymentRequestBody, payeeId: createdPayee.payeeId },
          });
          // The created payment request id will also be passed to the review page via props
          setPaymentsBuilderState('ready_to_review_external_request');
          setPaymentRequestId(paymentId as string);
        },
        onError: (e) => {
          // Create payee or payment request failed
          const errorMessage = getErrorMessage(e);
          let finalMessage = errorMessage;
          if (errorMessage === 'Invalid reference number') {
            finalMessage = 'The reference number provided is invalid for this Biller.';
          } else if (errorMessage === 'Invalid amount') {
            finalMessage = 'This Biller cannot accept the amount you have specified.';
          }
          showToast({ title: finalMessage, severity: 'danger' });
          setPaymentsBuilderState('ready_to_build');
        },
        onCancel: () => {
          setPaymentsBuilderState('reset_to_form');
        },
      });
    } else {
      await requireMfa({
        scope: 'payment:externalUnknownPayee',
        onSubmit: async ({ mfaId, mfaCode }) => {
          // Create payment request
          const { paymentId } = await createExternalPaymentRequest({
            data: { ...createExternalPaymentRequestBody, mfaId, mfaCode },
          });

          // The created payment request id will also be passed to the review page via props
          setPaymentsBuilderState('ready_to_review_external_request');
          setPaymentRequestId(paymentId as string);
        },
        onError: (e) => {
          // Create payment request failed
          const errorMessage = getErrorMessage(e);
          let finalMessage = errorMessage;
          if (errorMessage === 'Invalid reference number') {
            finalMessage = 'The reference number provided is invalid for this Biller.';
          } else if (errorMessage === 'Invalid amount') {
            finalMessage = 'This Biller cannot accept the amount you have specified.';
          }
          showToast({ title: finalMessage, severity: 'danger' });
          setPaymentsBuilderState('ready_to_build');
        },
        onCancel: () => {
          setPaymentsBuilderState('reset_to_form');
        },
      });
    }
  }, []);

  const createInternalTransferCallback = useCallback(async (confirmedPaymentFormValues: PaymentsFormValues) => {
    const paymentSchedule = getPaymentSchedule({
      startDate: confirmedPaymentFormValues.sendDelayed === 'Later' ? confirmedPaymentFormValues.startDate : '',
      endDate: confirmedPaymentFormValues.endDate,
      repeat: confirmedPaymentFormValues.repeat,
    });

    if (confirmedPaymentFormValues.payee.paymentType === 'AUS_INTERNAL_TRANSFER') {
      setPaymentsBuilderState('processing_internal_payment_request_BE');
      try {
        const results = await createInternalTransfer({
          data: {
            accountId: confirmedPaymentFormValues.accountId,
            amount: toNumberAmount(confirmedPaymentFormValues.amount, true),
            description: confirmedPaymentFormValues.description,
            paymentDetails: {
              toAccountId: confirmedPaymentFormValues.payee.paymentDetails.toAccountId,
            },
            paymentSchedule,
            paymentType: 'AUS_INTERNAL_TRANSFER',
          },
        });
        setTransactionResults(results);
        setPaymentsBuilderState('results_internal_payment_requests_ready');
      } catch (e) {
        if (Platform.OS === 'web') {
          // Internal transfer failed show toast on web
          showToast({ title: getErrorMessage(e), severity: 'danger' });
          setTimeout(() => {
            updatePaymentsServiceStateCallback('reset_to_form');
          }, 3000);
        } else {
          updatePaymentsServiceStateCallback('paymentExecutionError');
        }
      }
    }
  }, []);

  const executeExternalPaymentCallback = useCallback(async (confirmedPaymentRequestID: string) => {
    setPaymentsBuilderState('processing_external_payment_request_BE');

    try {
      // Validate payment request
      await queryClient.fetchQuery({
        queryKey: getPaymentValidationQueryKey(confirmedPaymentRequestID),
        queryFn: () => paymentValidation(confirmedPaymentRequestID),
      });

      // Process payment
      // If authorised required, PaymentRequest is returned, otherwise ExternalTransaction
      const externalPayment = await processExternalPayment({
        paymentId: confirmedPaymentRequestID,
      });
      setTransactionResults(externalPayment);
      setPaymentsBuilderState('results_external_payment_requests_ready');
    } catch (e) {
      if (Platform.OS === 'web') {
        // Internal transfer failed show toast on web
        showToast({ title: getErrorMessage(e), severity: 'danger' });
        setTimeout(() => {
          updatePaymentsServiceStateCallback('reset_to_form');
        }, 3000);
      } else {
        updatePaymentsServiceStateCallback('paymentExecutionError');
      }
    }
  }, []);

  return (
    <PaymentsBuilderServiceContext.Provider
      value={{
        paymentsBuilderState,
        paymentValues,
        startNewPaymentRequest: startNewPaymentRequestCallback,
        patchPaymentsRequest: patchPaymentRequestCallback,
        submitFormToPaymentsService: submitFormCallback,
        submitTransferRequestToBE: createInternalTransferCallback,
        updatePaymentsServiceState: updatePaymentsServiceStateCallback,
        executeExternalPaymentToBE: executeExternalPaymentCallback,
        paymentFormProps,
        fieldsToShow,
        transactionResults,
        paymentRequestId,
        paymentType,
        showAnimationStart,
        playPaymentSuccessAnimation,
        showExecutionError: paymentsBuilderState === 'paymentExecutionError',
      }}
    >
      {children}
    </PaymentsBuilderServiceContext.Provider>
  );
};
