import type { AccountDetails } from 'app/components';
import { NativeAccountSelectBase } from './NativeAccountSelectBase';

// Select label render used
export const fromAccountItemToLabelNative = (account: AccountDetails | null) => {
  if (!account) {
    return '';
  }

  return <NativeAccountSelectBase account={account} accountType="From" />;
};
