import type { BRAND } from 'zod';
import type { PaymentsFormValues } from 'app/features/payments/paymentsV2/buildPaymentsForms/types';
import type { PaymentTypes } from '../types';

// When calling the payments service or changing payment types, build a new payment payload
// Keep existing values if information has already been filled out on the form
export const generateNewPaymentRequestPayload = ({
  paymentType,
  oldPaymentValues,
  keepExistingInformation,
}: {
  paymentType: PaymentTypes;
  keepExistingInformation: boolean;
  oldPaymentValues?: Partial<PaymentsFormValues>;
}) => {
  const oldPaymentRequestPayload = { ...oldPaymentValues };
  let newPaymentValues;

  // Keep old payload if not stitching
  if (keepExistingInformation && oldPaymentValues) {
    newPaymentValues = oldPaymentRequestPayload;
    return { newPaymentValues };
  }

  // New payload
  newPaymentValues = {
    accountId: undefined,
    amount: undefined,
    description: undefined,
    frequency: 'Once',
    sendDelayed: 'Now' as string & BRAND<'select'>,
    date: new Date(),
    repeat: undefined,
  };

  switch (paymentType) {
    case 'AUS_INTERNAL_TRANSFER': {
      const finalPaymentValues = {
        ...newPaymentValues,
        payee: {
          paymentType: 'AUS_INTERNAL_TRANSFER', // Asserting here to satisfy external api. react-ts-form doesn't support literals
          name: undefined,
          nickname: undefined,
          paymentDetails: {
            toAccountId: undefined,
            accountNumber: undefined,
            bsb: undefined,
          },
        },
      } as unknown as PaymentsFormValues;
      return { newPaymentValues: finalPaymentValues };
    }
    case 'AUS_BANK_ACCOUNT': {
      const finalPaymentValues = {
        ...newPaymentValues,
        payee: {
          paymentType: 'AUS_BANK_ACCOUNT', // Asserting here to satisfy external api. react-ts-form doesn't support literals
          name: undefined,
          nickname: undefined,
          paymentDetails: {
            accountNumber: undefined,
            bsb: undefined,
          },
          supportedSchemes: [],
          savePayee: false,
          payeeId: '',
        },
      } as unknown as PaymentsFormValues;
      return { newPaymentValues: finalPaymentValues };
    }
    case 'AUS_BPAY': {
      const finalPaymentValues = {
        ...newPaymentValues,
        payee: {
          paymentType: 'AUS_BPAY', // Asserting here to satisfy external api. react-ts-form doesn't support literals
          name: undefined,
          nickname: undefined,
          paymentDetails: {
            billerCode: undefined,
            referenceNumber: undefined,
          },
          supportedSchemes: [],
          savePayee: false,
          payeeId: undefined,
          crnType: 'Standard',
        },
      } as unknown as PaymentsFormValues;
      return { newPaymentValues: finalPaymentValues };
    }
    case 'AUS_PAYID': {
      const finalPaymentValues = {
        ...newPaymentValues,
        payee: {
          paymentType: 'AUS_PAYID', // Asserting here to satisfy external api. react-ts-form doesn't support literals
          name: undefined,
          nickname: undefined,
          paymentDetails: {
            billerCode: undefined,
            referenceNumber: undefined,
            identifier: 'TELEPHONE',
          },
          supportedSchemes: [],
          savePayee: false,
          payeeId: undefined,
        },
      } as unknown as PaymentsFormValues;
      return { newPaymentValues: finalPaymentValues };
    }
  }
};
