import { Badge, H5, Text, YStack, XStack } from '@cxnpl/ui';
import currency from 'currency.js';
import type { AccountDetails } from 'app/components';
import { getAvailableCredit, getOverdraftAccountBalance, isOverdraftAccount } from 'app/features/accounts/utils';
import i18n from 'app/i18n/i18n.config';

export const NativeAccountSelectBase = ({
  account,
  accountType,
}: {
  account: AccountDetails;
  accountType: 'From' | 'To';
}) => {
  const { balance, overdraftValue, isAccountOverdrawn } = getOverdraftAccountBalance(account);

  // Create badge for credit limit
  let displayBadge: React.ReactNode = null;
  const availableCredit = getAvailableCredit(account) / 100;
  const formattedAmount = currency(availableCredit).format();

  if (isOverdraftAccount(account)) {
    if (availableCredit === overdraftValue) {
      // Case where none of the overdraft's credit limit has been drawn
      displayBadge = (
        <Badge size="small" appearance="info">
          {i18n.t('accounts.home.overdraftAvailable', {
            amount: formattedAmount,
          })}
        </Badge>
      );
    } else {
      displayBadge = (
        <Badge size="small" appearance="info">
          {i18n.t('accounts.home.overdraftRemaining', {
            amount: formattedAmount,
          })}
        </Badge>
      );
    }
  } else if (isAccountOverdrawn) {
    // Case where the account is unarranged and has been overdrawn
    displayBadge = (
      <Badge size="small" appearance="danger">
        {i18n.t('accounts.home.accountOverdrawn')}
      </Badge>
    );
  }

  return (
    <YStack
      key={account.accountId}
      width="100%"
      alignItems="flex-start"
      gap="$space.xs"
      accessibilityLabel={account.accountNumber}
      testID="account-label"
    >
      <XStack gap="$space.xs">
        <Text variant="bodySmall" color="$text/primary">
          {accountType}
        </Text>
        <Text variant="bodySmall" color="$form/color/form-fg-default">
          {account.nickname}
        </Text>
      </XStack>
      <H5>{currency(balance).format()}</H5>
      <Text variant="bodySmall" color="$foreground/app-subdued">
        {`BSB ${account.bsb} Acc ${account.accountNumber}`}
      </Text>
      {displayBadge}
    </YStack>
  );
};
