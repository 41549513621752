import { match } from 'ts-pattern';
import dayjs, { extend } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { validate as uuidValidate } from 'uuid';
import type { TermType } from '@cxnpl/api/types';
import i18n from 'app/i18n/i18n.config';

extend(utc);
extend(timezone);
extend(advancedFormat);

/**
 * Converts Sentence Case to 'Sentence case'
 * @param str - String in 'Sentence Case'
 * @returns String in 'Sentence case'
 */
export const toSentenceCase = (str: string) => {
  if (!str) {
    return '';
  }

  const trimmedInput = str.trim();
  const firstChar = trimmedInput.charAt(0);
  const restOfString = trimmedInput.slice(1);

  return firstChar.toUpperCase() + restOfString.toLowerCase();
};

/**
 * Converts string to 'Title Case'
 * @param str - String
 * @returns String in 'Title Case'
 */
export const toTitleCase = (str: string) => {
  if (!str) {
    return '';
  }

  return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};

/**
 * Converts number to interest percentage with a default of 2 decimal points.
 *
 * Support options to change precision.
 *
 * @param interest - Decimal value (e.g 0.06 or 6)
 * @param options -  Options
 * @returns Interest to set decimal places (e.g. 6.00%)
 **/
export const interestToString = (
  interest: number,
  options?: { perAnnum?: boolean; valueOnly?: boolean; isPercent?: boolean; precision?: number }
) => {
  const input = options?.isPercent ? interest : interest * 100;
  const value = input.toFixed(options?.precision ?? 2);
  if (options?.valueOnly) {
    return value;
  }
  const suffix = options?.perAnnum ? '% p.a.' : '%';
  return value + suffix;
};

type DateFormat =
  | 'date-short'
  | 'date-long'
  | 'date-time'
  | 'time'
  | 'date-iso'
  | 'date-day-month'
  | 'day-month-short'
  | 'date-day-month-year'
  | 'date-day-full-month-year'
  | 'date-day-month-year-zone'
  | 'date-day-month-year-time-zone'
  | 'date-day-month-year-short-time-zone'
  | 'date-year-long'
  | 'date-year-short'
  | 'month-year-long'
  | 'month-year-short'
  | 'date-day-month-year-short'
  | 'do-month-year';

/**
 * A datetime to string formatter, try to always use this function instead of raw dayjs.
 * If new formats needed, feel free to add them.
 * @param date - a dayjs date
 * @param format - how the date time will be formatted
 * ```
    'date-short': 'ddd D MMM',
    'date-long': 'dddd D MMM',
    'date-time': 'ddd D MMM HH:mm',
    'time': 'HH:mm',
    'date-iso': 'YYYY-MM-DD',
    'date-day-month': 'DD MMM',
    'day-month-short': 'D MMM',
    'date-day-month-year': 'DD MMM YYYY',
    'date-day-month-year-zone': 'DD MMM YYYY (zzz)',
    'date-day-month-year-time-zone': 'DD MMM YYYY, hh:mm A (zzz)',
    'date-year-long': 'dddd D MMMM YYYY',
    'date-year-short': 'ddd D MMMM YYYY',
    'month-year-long': 'MMMM YYYY',
    'month-year-short': 'MMM YYYY',
 * ```
 * @returns - a string of the date formatted accordingly
 */
export const dateTimeFormatter = (
  date: string | number | Date | dayjs.Dayjs | null | undefined,
  format: DateFormat
) => {
  const formatter = match(format)
    .with('date-short', () => 'ddd D MMM')
    .with('date-long', () => 'dddd D MMM')
    .with('date-time', () => 'ddd D MMM HH:mm')
    .with('time', () => 'HH:mm')
    .with('date-iso', () => 'YYYY-MM-DD')
    .with('date-day-month', () => 'DD MMM')
    .with('day-month-short', () => 'D MMM')
    .with('date-day-month-year', () => 'DD MMM YYYY')
    .with('date-day-full-month-year', () => 'DD MMMM YYYY')
    .with('date-day-month-year-zone', () => 'DD MMM YYYY (zzz)')
    .with('date-day-month-year-short-time-zone', () => 'DD MMM YYYY, hh:mm A (z)')
    .with('date-day-month-year-time-zone', () => 'DD MMM YYYY, hh:mm A (zzz)')
    .with('date-year-long', () => 'dddd D MMMM YYYY')
    .with('date-year-short', () => 'ddd D MMMM YYYY')
    .with('month-year-long', () => 'MMMM YYYY')
    .with('month-year-short', () => 'MMM YYYY')
    .with('date-day-month-year-short', () => 'DD/MM/YYYY')
    .with('do-month-year', () => 'Do MMM YYYY')
    .exhaustive();

  // eslint-disable-next-line no-restricted-syntax -- This is the one place that consolidates all the date formats used
  return dayjs(date).format(formatter);
};

export const isUUID = (uuid: string | null | undefined) => {
  if (!uuid) {
    return false;
  }
  return uuidValidate(uuid);
};

export function termFormatter(term: number, termType: TermType): string {
  switch (termType) {
    case 'YEARS':
      return `${term.toString()} ${i18n.t('lending.common.year', {
        count: term,
      })}`;
    case 'MONTHS':
      return `${term.toString()} ${i18n.t('lending.common.month', {
        count: term,
      })}`;
    case 'WEEKS':
      return `${term.toString()} ${i18n.t('lending.common.week', {
        count: term,
      })}`;
    case 'DAYS':
      return `${term.toString()} ${i18n.t('lending.common.day', {
        count: term,
      })}`;
  }
}
