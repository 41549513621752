import type { AccountDetails } from 'app/components';
import { NativeAccountSelectBase } from './NativeAccountSelectBase';

// TODO: most of this function should be extracted into a standalone component. The itemToLabel should only return the JSX for the account
export const ToAccountItemToLabelNative = (account: AccountDetails | null) => {
  if (!account) {
    return '';
  }
  return <NativeAccountSelectBase account={account} accountType="To" />;
};
