import type { ExternalPaymentRequestBody } from '@cxnpl/api/types';
import type { PaymentsFormValues } from 'app/features/payments/paymentsV2/buildPaymentsForms/types';
import { getPaymentSchedule } from 'app/features/payments/utils/paymentSchedule';
import { toNumberAmount } from 'app/utils/amountFormatter';

// TODO Unit test
// Testable function to generate the expected external payment payload
export const generateExternalPaymentPayload = (currentPaymentValues: Partial<PaymentsFormValues>) => {
  // Impossible condition, making TS happy
  if (currentPaymentValues.payee?.paymentType === 'AUS_INTERNAL_TRANSFER') {
    return;
  }

  // Get scheduled
  const paymentSchedule = getPaymentSchedule({
    startDate: currentPaymentValues.sendDelayed === 'Later' ? currentPaymentValues.startDate : '',
    endDate: currentPaymentValues.endDate,
    repeat: currentPaymentValues.repeat,
  });

  // Generate Body For External Payment
  let createExternalPaymentRequestBody: ExternalPaymentRequestBody;
  // PAYID
  if (currentPaymentValues.payee?.paymentType === 'AUS_PAYID') {
    const lookupId = currentPaymentValues.payee.lookupId;
    createExternalPaymentRequestBody = {
      accountId: currentPaymentValues.accountId,
      amount: toNumberAmount(currentPaymentValues.amount, true),
      description: currentPaymentValues.description,
      reference: currentPaymentValues.reference,
      payee: {
        paymentType: currentPaymentValues.payee.paymentType,
        lookupId,
      },
      paymentSchedule,
    };
  }
  // BPAY REQUEST
  else if (currentPaymentValues.payee?.paymentType === 'AUS_BPAY') {
    createExternalPaymentRequestBody = {
      accountId: currentPaymentValues.accountId,
      amount: toNumberAmount(currentPaymentValues.amount, true),
      description: currentPaymentValues.description,
      reference: currentPaymentValues.reference,
      payee: {
        name: currentPaymentValues.payee.name,
        nickname: currentPaymentValues.payee.nickname,
        paymentType: currentPaymentValues.payee.paymentType,
        paymentDetails: {
          billerCode: currentPaymentValues.payee.paymentDetails.billerCode,
          referenceNumber: currentPaymentValues.payee.paymentDetails.referenceNumber,
        },
      },
      paymentSchedule,
    };
  }
  // AUS_BANK_ACCOUNT
  else {
    createExternalPaymentRequestBody = {
      accountId: currentPaymentValues.accountId,
      amount: toNumberAmount(currentPaymentValues.amount, true),
      description: currentPaymentValues.description,
      reference: currentPaymentValues.reference,
      payee: {
        name: currentPaymentValues.payee?.name,
        nickname: currentPaymentValues.payee?.nickname,
        paymentType: currentPaymentValues.payee?.paymentType,
        paymentDetails: {
          bsb: currentPaymentValues.payee?.paymentDetails.bsb,
          accountNumber: currentPaymentValues.payee?.paymentDetails.accountNumber,
        },
      },
      paymentSchedule,
    };
  }
  return createExternalPaymentRequestBody;
};
