import type { PaymentSchedule } from '@cxnpl/api/types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { dateTimeFormatter } from 'app/utils/stringUtils';

dayjs.extend(utc);
dayjs.extend(timezone);

export enum PaymentFrequency {
  DAILY = 'DAIL',
  WEEKLY = 'WEEK',
  FORTNIGHTLY = 'FRTN',
  MONTHLY = 'MNTH',
  QUARTERLY = 'QURT',
  BIANNUALLY = 'MIAN',
  ANNUALLY = 'YEAR',
}

export const getPaymentFrequency = (frequency: string | undefined): PaymentFrequency | undefined => {
  switch (frequency) {
    case 'Daily':
      return PaymentFrequency.DAILY;

    case 'Weekly':
      return PaymentFrequency.WEEKLY;

    case 'Fortnightly':
      return PaymentFrequency.FORTNIGHTLY;

    case 'Monthly':
      return PaymentFrequency.MONTHLY;

    case 'Quarterly':
      return PaymentFrequency.QUARTERLY;

    case 'Biannually':
      return PaymentFrequency.BIANNUALLY;

    case 'Yearly':
      return PaymentFrequency.ANNUALLY;
    default:
      return undefined;
  }
};

export const getPaymentSchedule = ({
  startDate,
  endDate,
  repeat,
}: {
  startDate?: string;
  endDate?: string;
  repeat?: string;
}): PaymentSchedule => {
  let paymentSchedule: PaymentSchedule = {
    scheduleCategory: 'now',
  };

  const paymentFrequency = getPaymentFrequency(repeat);

  if (paymentFrequency) {
    paymentSchedule = {
      scheduleCategory: 'recurring',
      scheduleDetails: {
        ...(startDate && {
          // eslint-disable-next-line no-restricted-syntax -- Allowing raw dayjs format because this is a payload
          startDate: dayjs(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        }),
        ...(endDate && {
          // eslint-disable-next-line no-restricted-syntax -- Allowing raw dayjs format because this is a payload
          endDate: dayjs(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
        }),
        paymentFrequency,
      },
    };
  } else if (startDate) {
    paymentSchedule = {
      scheduleCategory: 'scheduled',
      scheduleDetails: {
        // eslint-disable-next-line no-restricted-syntax -- Allowing raw dayjs format because this is a payload
        paymentDate: dayjs(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      },
    };
  }

  return paymentSchedule;
};

export const getReadablePaymentFrequency = (frequency: PaymentFrequency): string => {
  switch (frequency) {
    case PaymentFrequency.DAILY:
      return 'Daily';

    case PaymentFrequency.WEEKLY:
      return 'Weekly';

    case PaymentFrequency.FORTNIGHTLY:
      return 'Fortnightly';

    case PaymentFrequency.MONTHLY:
      return 'Monthly';

    case PaymentFrequency.QUARTERLY:
      return 'Quarterly';

    case PaymentFrequency.BIANNUALLY:
      return 'Biannually';

    case PaymentFrequency.ANNUALLY:
      return 'Yearly';
    default:
      return frequency;
  }
};

/**
 * Payment schedule in a readable format
 * @param paymentSchedule - a PaymentSchedule object
 * @param tz - TimeZone string
 * @returns - a string with format DD MMM YYYY (zzz)
 */
export const getReadablePaymentSchedule = (
  paymentSchedule: PaymentSchedule,
  tz?: string
): {
  startDate?: string;
  endDate?: string;
  repeat?: string;
} => {
  let startDate: string | undefined;
  let endDate: string | undefined;
  let repeat: string | undefined;

  //TODO: this is a hardcoded default timeZone for payments. Where does it come from? Could it be retrieved from a config? From the BE?
  const defaultTimezone = 'Australia/Brisbane';

  if (paymentSchedule.scheduleCategory !== 'now') {
    if (paymentSchedule.scheduleCategory === 'scheduled') {
      startDate = dateTimeFormatter(
        dayjs
          .utc(paymentSchedule.scheduleDetails.paymentDate)
          .startOf('day')
          .tz(tz || defaultTimezone, true),
        'date-day-month-year-zone'
      );
    } else {
      // Recurring
      const start = dayjs
        .utc(paymentSchedule.scheduleDetails.startDate)
        .startOf('day')
        .tz(tz || defaultTimezone, true);

      const end = paymentSchedule.scheduleDetails.endDate
        ? dayjs
            .utc(paymentSchedule.scheduleDetails.endDate)
            .startOf('day')
            .tz(tz || defaultTimezone, true)
        : null;

      startDate = dateTimeFormatter(start, 'date-day-month-year-zone');
      endDate = end ? dateTimeFormatter(end, 'date-day-month-year-zone') : 'This payment will continue indefinitely';

      repeat = getReadablePaymentFrequency(paymentSchedule.scheduleDetails.paymentFrequency as PaymentFrequency);
    }
  }

  return {
    startDate,
    endDate,
    repeat,
  };
};
